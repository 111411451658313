import { IGenerate, ShowGenerateContentDrawerOptions } from "./generate";
import { IStandards } from "./standards";
import { IFiles } from "./files";

export * from "./generate";
export * from "./standards";
export * from "./files";

export interface IPear {
  setLogLevel: (logLevel: LogLevel) => void;

  /**
   * @deprecated Use `identity.identifyProduct` instead.
   */
  identifyProduct: IIdentity["identifyProduct"];
  /**
   * @deprecated Use `identity.identifyUser` instead.
   */
  identifyUser: IIdentity["identifyUser"];
  /**
   * @deprecated Use `identity.setToken` instead.
   */
  setToken: IIdentity["setToken"];
  /**
   * @deprecated Use `standards.openStandardsModal` instead.
   */
  openStandardsModal: IStandards["openStandardsModal"];

  /**
   * files provides methods for interacting with the Pear Files service.
   */
  files: IFiles;
  /**
   * generate provides methods for generating content.
   */
  generate: IGenerate;
  /**
   * identity provides methods for identifying the current user and product.
   */
  identity: IIdentity;
  /**
   * standards provides methods for PDL Unified Academic Standards.
   */
  standards: IStandards;
}

export interface IIdentity {
  identifyProduct: (product: Product) => void;
  identifyUser: (userIdentityToken: string) => void;
  setToken: (token: string) => void;
}

declare global {
  interface Window {
    pear: IPear;
  }
}

export type State = {
  product: string;

  token?: string;
  generateContentDrawerFrame?: HTMLIFrameElement;
  generateContentDrawerOptions?: Partial<ShowGenerateContentDrawerOptions>;
  launcherFrame?: HTMLIFrameElement;
  launcherGrid?: HTMLElement;
  launcherTarget?: HTMLElement;
  launcherIsOpen?: boolean;
  launcherFrameInnerHeight?: number;
  modalOverlay?: HTMLDivElement;
  modalEmail?: string;
  modalFrame?: HTMLIFrameElement;
  modalIsOpen?: boolean;
  modalProduct?: Product;
  signInTarget?: HTMLElement;
  signInFrame?: HTMLIFrameElement;
  onTokenQueue: Array<(state: State) => void>;

  modalCallback?: (data: any) => void;
  modalOverlayClickHandler: ((ev: MouseEvent) => void) | null;
  originalDocumentOnClick:
    | ((this: GlobalEventHandlers, ev: MouseEvent) => any)
    | null;
};

export enum TargetIds {
  GenerateContentFrame = "psi_generate_content_frame",
  Launcher = "psi_launcher",
  LauncherGrid = "psi_grid",
  LauncherFrame = "psi_launcher_frame",
  ModalOverlay = "psi_modal_overlay",
  ModalFrame = "psi_modal",
  SignIn = "psi_sign_in",
  SignInFrame = "psi_sign_in_frame",
}

export enum LogLevel {
  Debug = "DEBUG",
  Info = "INFO",
  Warn = "WARN",
  Error = "ERROR",
}

export enum Product {
  Assistant = "ASSISTANT",
  Edulastic = "EDULASTIC",
  GiantSteps = "GIANT_STEPS",
  PearDeck = "PEAR_DECK",
  TutorMe = "TUTOR_ME",

  InvalidProduct = "INVALID_PRODUCT",
}
