/* eslint-disable */
// @ts-nocheck
/*
 * This file is a generated Typescript file for GRPC Gateway, DO NOT MODIFY
 */

import * as PlaytimelibraryPracticeSetItems from "../playtime-library/practice-set-items.pb"


export enum GenerateContentType {
  TYPE_UNKNOWN = "TYPE_UNKNOWN",
  TYPE_PEAR_DECK = "TYPE_PEAR_DECK",
  TYPE_PEAR_ASSESSMENT_TEST = "TYPE_PEAR_ASSESSMENT_TEST",
  TYPE_PEAR_PRACTICE_SET = "TYPE_PEAR_PRACTICE_SET",
}

export enum ReadingLevelDifferentiation {
  READING_LEVEL_UNKNOWN = "READING_LEVEL_UNKNOWN",
  READING_LEVEL_BELOW = "READING_LEVEL_BELOW",
  READING_LEVEL_AT = "READING_LEVEL_AT",
  READING_LEVEL_ABOVE = "READING_LEVEL_ABOVE",
}

export enum DeckSourceIntent {
  SOURCE_INTENT_UNKNOWN = "SOURCE_INTENT_UNKNOWN",
  SOURCE_INTENT_TOPIC_INSTRUCTION = "SOURCE_INTENT_TOPIC_INSTRUCTION",
  SOURCE_INTENT_CONTENT_PREP = "SOURCE_INTENT_CONTENT_PREP",
  SOURCE_INTENT_CONTENT_REVIEW = "SOURCE_INTENT_CONTENT_REVIEW",
}

export enum DeckLessonType {
  LESSON_TYPE_UNKNOWN = "LESSON_TYPE_UNKNOWN",
  LESSON_TYPE_FULL = "LESSON_TYPE_FULL",
  LESSON_TYPE_MINI = "LESSON_TYPE_MINI",
  LESSON_TYPE_EXIT_TICKETS = "LESSON_TYPE_EXIT_TICKETS",
}

export enum AssessmentItemType {
  ITEM_TYPE_UNKNOWN = "ITEM_TYPE_UNKNOWN",
  ITEM_TYPE_MULTIPLE_CHOICE = "ITEM_TYPE_MULTIPLE_CHOICE",
  ITEM_TYPE_TRUE_FALSE = "ITEM_TYPE_TRUE_FALSE",
  ITEM_TYPE_MULTIPLE_SELECT = "ITEM_TYPE_MULTIPLE_SELECT",
}

export enum AssessmentDOK {
  DOK_UNKNOWN = "DOK_UNKNOWN",
  DOK_1_RECALL = "DOK_1_RECALL",
  DOK_2_SKILL_CONCEPT = "DOK_2_SKILL_CONCEPT",
  DOK_3_STRATEGIC_THINKING = "DOK_3_STRATEGIC_THINKING",
  DOK_4_EXTENDED_THINKING = "DOK_4_EXTENDED_THINKING",
}

export enum AssessmentDifficulty {
  DIFFICULTY_UNKNOWN = "DIFFICULTY_UNKNOWN",
  DIFFICULTY_EASY = "DIFFICULTY_EASY",
  DIFFICULTY_MEDIUM = "DIFFICULTY_MEDIUM",
  DIFFICULTY_HARD = "DIFFICULTY_HARD",
}

export enum GenerateContentResponseStatus {
  STATUS_UNKNOWN = "STATUS_UNKNOWN",
  STATUS_READING_INPUT = "STATUS_READING_INPUT",
  STATUS_STARTING_GENERATION = "STATUS_STARTING_GENERATION",
  STATUS_ITEM_COMPLETE = "STATUS_ITEM_COMPLETE",
  STATUS_ITEM_ERROR = "STATUS_ITEM_ERROR",
  STATUS_DONE = "STATUS_DONE",
  STATUS_PING = "STATUS_PING",
}

export type GenerateContentRequestGenerateContent = {
  request_id: string
  type: GenerateContentType | null
  reading_level_differentiation: ReadingLevelDifferentiation | null
}

export type GenerateContentRequestPracticeSetConfig = {
  question_types: PlaytimelibraryPracticeSetItems.QuestionType[] | null
  min_question_count: number
  max_question_count: number
  extract_questions: boolean
}

export type GenerateContentRequestDeckConfig = {
  source_intent: DeckSourceIntent | null
  lesson_type: DeckLessonType | null
}

export type GenerateContentRequestAssessmentConfig = {
  item_type: AssessmentItemType | null
  num_items: number
  dok: AssessmentDOK | null
  difficulty: AssessmentDifficulty | null
  test_name: string
}

export type GenerateContentRequest = {
  requests: GenerateContentRequestGenerateContent[] | null
  /**
   * Source inputs for content generation, common to all products
   */
  topic: string
  context: string
  source_urls: string[]
  upload_urls: string[]
  /**
   * Configs common to all products
   */
  grade_level_id: string
  subject_id: string
  standard_ids: string[]
  /**
   * Product-specific configs
   */
  practice_set_config: GenerateContentRequestPracticeSetConfig | null
  deck_config: GenerateContentRequestDeckConfig | null
  assessment_config: GenerateContentRequestAssessmentConfig | null
}

export type GenerateContentResponse = {
  request_id: string
  status: GenerateContentResponseStatus | null
  generated_content_url: string
  generated_content_id: string
  generated_content_title: string
  generated_content_num_items: number
}


